/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
div.btn-w-xs {
  min-width: 80px !important; }

div.btn-w-sm {
  min-width: 100px !important; }

div.btn-w-md {
  min-width: 135px !important; }

div.btn-w-lg {
  min-width: 160px !important; }

.app-sidebar .nav-icon,
.app-sidebar .nav-text {
  vertical-align: middle; }

.app-sidebar .nav-icon {
  margin-top: -2px; }

.app-sidebar .nav li > a {
  display: block !important;
  text-align: left !important;
  min-width: inherit !important;
  padding: 10px 16px;
  border-radius: 0 !important;
  height: inherit !important;
  line-height: 24px !important; }

.app-header .bg-color-dark .header-btn,
.app-header .bg-color-primary .header-btn,
.app-header .bg-color-success .header-btn,
.app-header .bg-color-info .header-btn,
.app-header .bg-color-danger .header-btn {
  color: #fff !important; }

.header-icon-dropdown-item {
  font-size: 14px !important; }
  .header-icon-dropdown-item > div > div {
    padding-left: 56px !important; }
  .header-icon-dropdown-item .text-muted {
    font-size: 0.875rem !important; }

.theme-dark .app-header .bg-color-light .header-btn,
.theme-dark .app-header .bg-color-warning .header-btn,
.theme-gray .app-header .bg-color-light .header-btn,
.theme-gray .app-header .bg-color-warning .header-btn {
  color: #fff !important; }

.theme-dark .quickview-wrapper .customizer-layout-options label,
.theme-gray .quickview-wrapper .customizer-layout-options label {
  color: rgba(255, 255, 255, 0.7) !important; }

.quickview-wrapper .customizer-layout-options label {
  color: rgba(0, 0, 0, 0.87) !important; }

.quickview-wrapper .customizer-layout-options .sidebar-width-select > div > div > div {
  color: rgba(0, 0, 0, 0.87) !important; }

.quickview-wrapper .customizer-layout-options .sidebar-width-select > div > hr {
  border-color: rgba(0, 0, 0, 0.15) !important; }

#app-container,
#app-inner,
.main-app-container {
  height: 100%; }

.logo-react .react-dot,
.logo-react .react-curve {
  opacity: .9; }

.logo-react .react-dot {
  fill: #fff; }

.logo-react .react-curve {
  stroke: #fff; }

.bg-color-warning > .logo-react .react-dot,
.bg-color-light > .logo-react .react-dot {
  fill: rgba(0, 0, 0, 0.87); }

.bg-color-warning > .logo-react .react-curve,
.bg-color-light > .logo-react .react-curve {
  stroke: rgba(0, 0, 0, 0.87); }

.theme-gray .bg-color-warning > .logo-react .react-dot,
.theme-gray .bg-color-warning > .logo-react .react-curve,
.theme-gray .bg-color-light > .logo-react .react-dot,
.theme-gray .bg-color-light > .logo-react .react-curve,
.theme-dark .bg-color-warning > .logo-react .react-dot,
.theme-dark .bg-color-warning > .logo-react .react-curve,
.theme-dark .bg-color-light > .logo-react .react-dot,
.theme-dark .bg-color-light > .logo-react .react-curve {
  opacity: .7; }

.theme-gray .bg-color-warning > .logo-react .react-dot,
.theme-gray .bg-color-light > .logo-react .react-dot,
.theme-dark .bg-color-warning > .logo-react .react-dot,
.theme-dark .bg-color-light > .logo-react .react-dot {
  fill: #fff; }

.theme-gray .bg-color-warning > .logo-react .react-curve,
.theme-gray .bg-color-light > .logo-react .react-curve,
.theme-dark .bg-color-warning > .logo-react .react-curve,
.theme-dark .bg-color-light > .logo-react .react-curve {
  stroke: #fff; }

@media only screen and (min-width: 992px) {
  .nav-collapsed .app-sidebar .sidebar-header .logo-react {
    margin-bottom: -5px; } }

.chapter .ui-animate > div > .article .article-title {
  margin: 48px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.117647); }

.chapter .ui-animate > div:nth-of-type(1) > .article .article-title {
  margin: 0 0 30px;
  border-top: 0; }

.typo-styles dt {
  display: block;
  float: left;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px; }

.typo-styles dd {
  display: block;
  margin-left: 80px;
  margin-bottom: 20px; }

.typo-styles .typo-styles__demo {
  margin-bottom: 8px; }

.page-icons .card .fa,
.page-icons .card .material-icons,
.page-icons .card .wi {
  color: rgba(0, 0, 0, 0.5); }

.page-icons .card .fa,
.page-icons .card .wi {
  font-size: 20px;
  margin: 5px; }

.theme-gray .page-icons .card .fa,
.theme-gray .page-icons .card .material-icons,
.theme-gray .page-icons .card .wi,
.theme-dark .page-icons .card .fa,
.theme-dark .page-icons .card .material-icons,
.theme-dark .page-icons .card .wi {
  color: rgba(255, 255, 255, 0.7);
  opacity: .7; }

.page-grids .grid-structure .row {
  margin-top: .8rem; }
  .page-grids .grid-structure .row .widget-container {
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.1);
    padding: 10px 15px 12px;
    font-size: 0.875rem;
    min-height: 0;
    border-radius: 0.2rem; }

.color-palette {
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 60px; }
  .color-palette ul {
    margin: 0;
    padding: 0; }
  .color-palette .dark {
    color: rgba(0, 0, 0, 0.87); }
  .color-palette .color-group {
    padding-bottom: 40px; }
  .color-palette .color-group:first-child,
  .color-palette .color-group:nth-of-type(3n+1),
  .color-palette .color-group:last-child:first-child,
  .color-palette .color-group:last-child:nth-of-type(3n+1) {
    clear: left;
    margin-left: 0; }
  .color-palette .color-group li.divide,
  .color-palette .color-group:last-child li.divide {
    border-top: 4px solid #fafafa; }
  .color-palette .color-group li.color,
  .color-palette .color-group:last-child li.color {
    padding: 15px; }
  .color-palette .color-group li,
  .color-palette .color-group:last-child li {
    list-style-type: none; }
  .color-palette .color-group li.main-color,
  .color-palette .color-group:last-child li.main-color {
    border-bottom: 4px solid #fafafa; }
  .color-palette .color-group li.main-color .name,
  .color-palette .color-group:last-child li.main-color .name {
    display: block;
    margin-bottom: 60px; }
  .color-palette .color-group li.color .hex,
  .color-palette .color-group:last-child li.color .hex {
    float: right;
    text-transform: uppercase; }

.body-auth {
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover; }

.page-auth,
.page-login,
.page-signup,
.page-forgot {
  padding: 0 10px; }
  .page-auth .main-body,
  .page-login .main-body,
  .page-signup .main-body,
  .page-forgot .main-body {
    max-width: 480px;
    margin: 0 auto;
    padding-top: 50px; }
    @media (min-width: 768px) {
      .page-auth .main-body,
      .page-login .main-body,
      .page-signup .main-body,
      .page-forgot .main-body {
        padding-top: 150px; } }
  .page-auth .body-inner,
  .page-login .body-inner,
  .page-signup .body-inner,
  .page-forgot .body-inner {
    position: relative;
    padding: 20px; }
    .page-auth .body-inner:before,
    .page-login .body-inner:before,
    .page-signup .body-inner:before,
    .page-forgot .body-inner:before {
      z-index: 0;
      content: ' ';
      line-height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #2196F3;
      height: 220px; }
  .page-auth .additional-info,
  .page-login .additional-info,
  .page-signup .additional-info,
  .page-forgot .additional-info {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 12px; }
    .page-auth .additional-info a,
    .page-login .additional-info a,
    .page-signup .additional-info a,
    .page-forgot .additional-info a {
      color: #fafafa; }
    .page-auth .additional-info .divider-h,
    .page-login .additional-info .divider-h,
    .page-signup .additional-info .divider-h,
    .page-forgot .additional-info .divider-h {
      border-right: 1px solid #fafafa;
      margin: 0 15px; }
  .page-auth h1,
  .page-login h1,
  .page-signup h1,
  .page-forgot h1 {
    font-weight: normal;
    color: #2196F3;
    font-size: 38px;
    margin-bottom: 40px; }
    .page-auth h1 a,
    .page-login h1 a,
    .page-signup h1 a,
    .page-forgot h1 a {
      color: #2196F3; }
  .page-auth .card,
  .page-login .card,
  .page-signup .card,
  .page-forgot .card {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
    .page-auth .card .card-content,
    .page-login .card .card-content,
    .page-signup .card .card-content,
    .page-forgot .card .card-content {
      padding: 30px 20px; }
  .page-auth .ui-input-group .form-control,
  .page-login .ui-input-group .form-control,
  .page-signup .ui-input-group .form-control,
  .page-forgot .ui-input-group .form-control {
    color: #495057; }
  .page-auth .form-group,
  .page-login .form-group,
  .page-signup .form-group,
  .page-forgot .form-group {
    position: relative;
    margin: 0; }
  .page-auth .wrapper,
  .page-login .wrapper,
  .page-signup .wrapper,
  .page-forgot .wrapper {
    margin-top: 50px; }
    @media (min-width: 768px) {
      .page-auth .wrapper,
      .page-login .wrapper,
      .page-signup .wrapper,
      .page-forgot .wrapper {
        margin-top: 150px; } }
  .page-auth .logo,
  .page-login .logo,
  .page-signup .logo,
  .page-forgot .logo {
    font-size: 26px;
    font-weight: normal; }
    .page-auth .logo a:hover,
    .page-login .logo a:hover,
    .page-signup .logo a:hover,
    .page-forgot .logo a:hover {
      text-decoration: none; }

.page-confirm-email .logo {
  font-size: 24px;
  margin-bottom: .8em; }

.page-confirm-email .confirm-mail-icon {
  text-align: center; }
  .page-confirm-email .confirm-mail-icon .material-icons {
    color: #868e96;
    font-size: 100px; }

.page-err {
  background-color: #333C44;
  height: 100%;
  position: relative; }
  .page-err .err-container {
    background-color: #333C44;
    padding: 45px 10px 0; }
    @media (min-width: 768px) {
      .page-err .err-container {
        padding: 100px 0 0; } }
  .page-err .err {
    color: #fafafa; }
    .page-err .err h1 {
      margin-bottom: 35px;
      color: #fafafa;
      color: rgba(255, 255, 255, 0.8);
      font-size: 150px;
      font-weight: 300;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }
      @media (min-width: 768px) {
        .page-err .err h1 {
          font-size: 180px; } }
    .page-err .err h2 {
      color: #fafafa;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
      font-weight: 300;
      font-size: 28px;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .page-err .err h2 {
          font-size: 36px; } }
  .page-err .err-body {
    padding: 20px 10px; }
  .page-err .btn-goback {
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: #fff;
    border-color: rgba(255, 255, 255, 0.8); }
    .page-err .btn-goback:hover, .page-err .btn-goback:focus, .page-err .btn-goback:active, .page-err .btn-goback.active {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1); }
    .open .page-err .btn-goback.dropdown-toggle {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1); }
  .page-err .footer {
    position: absolute;
    bottom: 20px;
    width: 100%; }

.page-lock {
  height: 100%;
  background: url("../../assets/images/background/1.png") no-repeat center center fixed;
  background-size: cover; }
  .page-lock .lock-centered {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -65px; }
    @media screen and (min-width: 768px) {
      .page-lock .lock-centered {
        margin-top: -75px; } }
  .page-lock .lock-container {
    position: relative;
    max-width: 420px;
    margin: 0 auto; }
  .page-lock .lock-box {
    position: absolute;
    left: 0;
    right: 0; }
    .page-lock .lock-box .lock-user {
      background: #fff;
      width: 50%;
      float: left;
      height: 50px;
      line-height: 50px;
      margin-top: 50px;
      padding: 0 20px;
      border-left-radius: 0.2rem 0 0 0.2rem;
      color: #868e96; }
    .page-lock .lock-box .lock-img img {
      position: absolute;
      border-radius: 50%;
      left: 40%;
      width: 80px;
      height: 80px;
      border: 6px solid #fff;
      background: #fff; }
      @media screen and (min-width: 768px) {
        .page-lock .lock-box .lock-img img {
          left: 33%;
          width: 150px;
          height: 150px;
          border: 10px solid #fff; } }
    .page-lock .lock-box .lock-pwd {
      background: #fff;
      width: 50%;
      float: right;
      height: 50px;
      line-height: 50px;
      padding: 0 0 0 50px;
      margin-top: 50px;
      border-right-radius: 0 0.2rem 0.2rem 0;
      color: #2196F3; }
      @media screen and (min-width: 768px) {
        .page-lock .lock-box .lock-pwd {
          padding: 0 0 0 80px; } }
      .page-lock .lock-box .lock-pwd input {
        width: 80%;
        height: 50px;
        color: #495057;
        border: 0; }
      .page-lock .lock-box .lock-pwd .btn-submit {
        position: absolute;
        top: 50%;
        right: 20px;
        color: rgba(0, 0, 0, 0.87); }
        .page-lock .lock-box .lock-pwd .btn-submit .material-icons {
          line-height: 50px;
          height: 50px; }

.page-profile .profile-header {
  position: relative;
  margin: 0 0 15px;
  padding: 50px 30px 90px;
  background: url("../../assets/images//background/1.png") no-repeat center center fixed;
  background-size: cover; }

.page-profile .profile-img {
  display: inline-block;
  margin-right: 20px; }
  .page-profile .profile-img img {
    max-width: 120px;
    height: auto;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.2); }

.page-profile .profile-social {
  display: inline-block; }
  .page-profile .profile-social > a {
    margin-right: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.page-profile .profile-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 10px 30px;
  color: #fafafa; }
  .page-profile .profile-info ul {
    margin: 0; }

.page-profile img.media-object {
  border-radius: 0.2rem; }

.page-invoice {
  color: rgba(0, 0, 0, 0.87); }
  .page-invoice .invoice-wrapper {
    padding: 0 0 30px;
    background-color: #fff; }

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: #fff; }
  .invoice-inner .invoice-sum li {
    margin-bottom: 5px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.2rem; }
  .invoice-inner .table.table-bordered {
    border: 0; }
  .invoice-inner .table .bg-color-dark > th {
    border: 0; }

.page-dashboard .metrics {
  margin-top: 50px; }
  .page-dashboard .metrics .metric-box {
    margin-bottom: 15px; }
  .page-dashboard .metrics .metric {
    display: block;
    margin-bottom: 10px;
    font-size: 26px;
    color: #00BCD4; }
  .page-dashboard .metrics .metric-info {
    text-transform: uppercase;
    font-size: 16px;
    color: #aaa; }

.page-dashboard .box {
  position: relative;
  border-radius: 0.2rem; }
  .page-dashboard .box .box-top,
  .page-dashboard .box .box-bottom {
    height: 100px;
    padding: 32px 15px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 300; }
    .page-dashboard .box .box-top .size-h5,
    .page-dashboard .box .box-bottom .size-h5 {
      font-size: 24px;
      margin-left: 2px; }
    .page-dashboard .box .box-top .material-icons,
    .page-dashboard .box .box-bottom .material-icons {
      font-size: 40px;
      line-height: 40px; }
  .page-dashboard .box .box-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
    @media only screen and (min-width: 768px) {
      .page-dashboard .box .box-bottom {
        padding-left: 15%;
        padding-right: 15%; } }
  .page-dashboard .box .box-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center; }
    .page-dashboard .box .box-info span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 12px;
      color: #868e96;
      border-radius: 1em; }
      .page-dashboard .box .box-info span .material-icons {
        line-height: 14px;
        font-size: 11px; }

.theme-gray .box-info .box-icon .material-icons,
.theme-dark .box-info .box-icon .material-icons {
  color: rgba(255, 255, 255, 0.54); }

.theme-gray .box-info .box-num,
.theme-dark .box-info .box-num {
  color: rgba(255, 255, 255, 0.54); }

.theme-gray .page-dashboard .box .box-info span {
  background-color: #444; }

.theme-dark .page-dashboard .box .box-info span {
  background-color: #38424b; }

.page-maintenance .top-header {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 32px;
  line-height: 1; }
  .page-maintenance .top-header a.logo {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87); }

.page-maintenance .content {
  max-width: 1140px;
  margin: 50px auto 0; }

.page-maintenance .main-content {
  margin-bottom: 80px; }
  .page-maintenance .main-content h1 {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 15px; }
  .page-maintenance .main-content p {
    font-size: 22px; }

.theme-dark .page-maintenance .top-header a.logo,
.theme-gray .page-maintenance .top-header a.logo {
  color: #fff; }

.page-about .hero.hero-bg-img {
  background-size: cover;
  padding: 0;
  background-position: center center; }

.page-about .hero .hero-inner {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 90px 0; }

.page-about .hero .hero-title,
.page-about .hero .hero-tagline {
  color: #fff; }

.page-about .stat-container {
  margin-bottom: 30px; }
  .page-about .stat-container .stat-item {
    margin-bottom: 20px;
    border-bottom: 2px solid #f5f5f5; }
  .page-about .stat-container .stat-num {
    display: block;
    color: #2196F3;
    font-size: 72px;
    font-weight: 300;
    line-height: 66px; }
  .page-about .stat-container .stat-desc {
    display: inline-block;
    margin-bottom: -2px;
    padding-bottom: 20px;
    border-bottom: 2px solid #2196F3;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold; }

.page-about .article:nth-of-type(1) {
  padding-top: 75px; }

.page-about .space-bar {
  padding: 3px;
  border-radius: 0.2rem;
  margin-right: 5px; }

.page-terms h4 {
  margin-top: 2em;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase; }

.blog-item {
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  margin-top: 70px;
  padding: 70px 0 10px; }
  .blog-item:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0; }
  .blog-item h2 {
    font-size: 35px;
    line-height: 1; }
    .blog-item h2 a {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 300;
      text-decoration: none; }
  .blog-item .blog-info {
    margin: 10px 0; }
    .blog-item .blog-info > span {
      margin-right: 8px; }
    .blog-item .blog-info .avatar {
      width: 30px;
      height: auto;
      border-radius: 50%; }
    .blog-item .blog-info .date {
      opacity: .7; }
    .blog-item .blog-info .category {
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 0.2rem;
      background-color: rgba(0, 0, 0, 0.08); }
      .blog-item .blog-info .category a {
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
        opacity: .7; }
  .blog-item .desc {
    font-size: 16px;
    opacity: .7; }

.theme-gray .blog-item h2 a,
.theme-dark .blog-item h2 a {
  color: rgba(255, 255, 255, 0.7); }

.theme-gray .blog-item .blog-info .category a,
.theme-dark .blog-item .blog-info .category a {
  color: rgba(255, 255, 255, 0.7); }
