/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.bg-color-dark .bg-color-light a {
  color: rgba(0, 0, 0, 0.87); }

.bg-color-body {
  background-color: #e5e5e5;
  color: rgba(0, 0, 0, 0.87); }
  .bg-color-body:hover {
    background-color: #e5e5e5;
    color: rgba(0, 0, 0, 0.87); }
  .bg-color-body a {
    color: rgba(0, 0, 0, 0.87); }
    .bg-color-body a:hover {
      color: rgba(0, 0, 0, 0.87); }

.bg-color-page {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87); }
  .bg-color-page:hover {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.87); }
  .bg-color-page a {
    color: rgba(0, 0, 0, 0.87); }
    .bg-color-page a:hover {
      color: rgba(0, 0, 0, 0.87); }

.bg-color-light {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87); }
  .bg-color-light:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87); }
  .bg-color-light a {
    color: rgba(0, 0, 0, 0.87); }
    .bg-color-light a:hover {
      color: rgba(0, 0, 0, 0.87); }

.bg-color-white {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87); }
  .bg-color-white:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87); }
  .bg-color-white a {
    color: rgba(0, 0, 0, 0.87); }
    .bg-color-white a:hover {
      color: rgba(0, 0, 0, 0.87); }

.bg-color-gray {
  background-color: #636c72;
  color: #fff; }
  .bg-color-gray:hover {
    background-color: #636c72;
    color: #fff; }
  .bg-color-gray a {
    color: #fff; }
    .bg-color-gray a:hover {
      color: #fff; }

.bg-color-dark {
  background-color: #333C44;
  color: #fff; }
  .bg-color-dark:hover {
    background-color: #333C44;
    color: #fff; }
  .bg-color-dark a {
    color: #fff; }
    .bg-color-dark a:hover {
      color: #fff; }

.bg-color-primary {
  background-color: #2196F3;
  color: #fff; }
  .bg-color-primary:hover {
    background-color: #2196F3;
    color: #fff; }
  .bg-color-primary a {
    color: #fff; }
    .bg-color-primary a:hover {
      color: #fff; }

.bg-color-success {
  background-color: #66BB6A;
  color: #fff; }
  .bg-color-success:hover {
    background-color: #66BB6A;
    color: #fff; }
  .bg-color-success a {
    color: #fff; }
    .bg-color-success a:hover {
      color: #fff; }

.bg-color-info {
  background-color: #00BCD4;
  color: #fff; }
  .bg-color-info:hover {
    background-color: #00BCD4;
    color: #fff; }
  .bg-color-info a {
    color: #fff; }
    .bg-color-info a:hover {
      color: #fff; }

.bg-color-warning {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87); }
  .bg-color-warning:hover {
    background-color: #ffc107;
    color: rgba(0, 0, 0, 0.87); }
  .bg-color-warning a {
    color: rgba(0, 0, 0, 0.87); }
    .bg-color-warning a:hover {
      color: rgba(0, 0, 0, 0.87); }

.bg-color-danger {
  background-color: #EF5350;
  color: #fff; }
  .bg-color-danger:hover {
    background-color: #EF5350;
    color: #fff; }
  .bg-color-danger a {
    color: #fff; }
    .bg-color-danger a:hover {
      color: #fff; }

.mdl-data-table tbody .bg-color-dark {
  background-color: #333C44;
  color: #fff; }
  .mdl-data-table tbody .bg-color-dark:hover {
    background-color: #333C44;
    color: #fff; }
  .mdl-data-table tbody .bg-color-dark a {
    color: #fff; }
    .mdl-data-table tbody .bg-color-dark a:hover {
      color: #fff; }

.mdl-data-table tbody .bg-color-primary {
  background-color: #2196F3;
  color: #fff; }
  .mdl-data-table tbody .bg-color-primary:hover {
    background-color: #2196F3;
    color: #fff; }
  .mdl-data-table tbody .bg-color-primary a {
    color: #fff; }
    .mdl-data-table tbody .bg-color-primary a:hover {
      color: #fff; }

.mdl-data-table tbody .bg-color-success {
  background-color: #66BB6A;
  color: #fff; }
  .mdl-data-table tbody .bg-color-success:hover {
    background-color: #66BB6A;
    color: #fff; }
  .mdl-data-table tbody .bg-color-success a {
    color: #fff; }
    .mdl-data-table tbody .bg-color-success a:hover {
      color: #fff; }

.mdl-data-table tbody .bg-color-info {
  background-color: #00BCD4;
  color: #fff; }
  .mdl-data-table tbody .bg-color-info:hover {
    background-color: #00BCD4;
    color: #fff; }
  .mdl-data-table tbody .bg-color-info a {
    color: #fff; }
    .mdl-data-table tbody .bg-color-info a:hover {
      color: #fff; }

.mdl-data-table tbody .bg-color-danger {
  background-color: #EF5350;
  color: #fff; }
  .mdl-data-table tbody .bg-color-danger:hover {
    background-color: #EF5350;
    color: #fff; }
  .mdl-data-table tbody .bg-color-danger a {
    color: #fff; }
    .mdl-data-table tbody .bg-color-danger a:hover {
      color: #fff; }

.color-option-check {
  position: relative;
  display: block; }
  .color-option-check input[type="radio"] {
    display: none; }
  .color-option-check input[type="radio"] + span:hover {
    cursor: pointer; }
  .color-option-check input[type="radio"] + span {
    position: relative; }
    .color-option-check input[type="radio"] + span > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 30px;
      color: #fff; }
  .color-option-check input[type="radio"]:checked + span > .overlay {
    display: block; }
  .color-option-check .color-option-item {
    overflow: hidden;
    display: block;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px; }
    .color-option-check .color-option-item > span {
      display: block;
      float: left;
      width: 50%;
      height: 20px; }
    .color-option-check .color-option-item .item-header {
      height: 10px; }

.color-option-check .bg-color-page {
  background-color: #f1f1f1; }

.theme-options > div {
  padding: 0; }

.theme-option-check {
  position: relative;
  display: block;
  margin: 0;
  font-weight: normal; }
  .theme-option-check input[type="radio"] {
    display: none; }
  .theme-option-check input[type="radio"] + span:hover {
    cursor: pointer; }
  .theme-option-check input[type="radio"] + span {
    position: relative; }
    .theme-option-check input[type="radio"] + span > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 60px;
      color: #fff; }
      .theme-option-check input[type="radio"] + span > .overlay .material-icons {
        vertical-align: bottom;
        color: #66BB6A; }
  .theme-option-check input[type="radio"]:checked + span > .overlay {
    display: block; }
  .theme-option-check .theme-option-item {
    overflow: hidden;
    display: block; }
    .theme-option-check .theme-option-item > span {
      display: block;
      text-align: center;
      height: 60px;
      line-height: 60px;
      text-transform: uppercase; }

.app-sidebar.bg-color-light .nav {
  color: rgba(0, 0, 0, 0.87) !important; }
  .app-sidebar.bg-color-light .nav a {
    color: rgba(0, 0, 0, 0.87) !important; }
  .app-sidebar.bg-color-light .nav .nav-header {
    color: #868e96; }
  .app-sidebar.bg-color-light .nav li > a:hover, .app-sidebar.bg-color-light .nav li > a:focus {
    background-color: transparent !important;
    color: #2196F3 !important; }
  .app-sidebar.bg-color-light .nav li.active > a,
  .app-sidebar.bg-color-light .nav li.active > a:hover,
  .app-sidebar.bg-color-light .nav li.active > a:focus {
    background-color: transparent !important;
    color: #2196F3 !important; }
  .app-sidebar.bg-color-light .nav li.open > a,
  .app-sidebar.bg-color-light .nav li.open > a:hover,
  .app-sidebar.bg-color-light .nav li.open > a:focus {
    background-color: rgba(0, 0, 0, 0.05) !important;
    color: #2196F3 !important; }
  .app-sidebar.bg-color-light .nav li.open > .icon-has-ul {
    color: #2196F3; }
  .app-sidebar.bg-color-light .nav li > a:focus {
    background-color: transparent; }
  .app-sidebar.bg-color-light .nav ul {
    background-color: rgba(0, 0, 0, 0.05); }
    .app-sidebar.bg-color-light .nav ul li.active > a,
    .app-sidebar.bg-color-light .nav ul li.active > a:hover,
    .app-sidebar.bg-color-light .nav ul li.active > a:focus, .app-sidebar.bg-color-light .nav ul li.open > a,
    .app-sidebar.bg-color-light .nav ul li.open > a:hover,
    .app-sidebar.bg-color-light .nav ul li.open > a:focus {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: #2196F3 !important; }
  .app-sidebar.bg-color-light .nav ul ul {
    background-color: rgba(0, 0, 0, 0.05); }
    .app-sidebar.bg-color-light .nav ul ul > li.active > a,
    .app-sidebar.bg-color-light .nav ul ul > li.active > a:hover,
    .app-sidebar.bg-color-light .nav ul ul > li.active > a:focus, .app-sidebar.bg-color-light .nav ul ul > li.open > a,
    .app-sidebar.bg-color-light .nav ul ul > li.open > a:hover,
    .app-sidebar.bg-color-light .nav ul ul > li.open > a:focus {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: #2196F3 !important; }
  .app-sidebar.bg-color-light .nav ul ul ul {
    background-color: rgba(0, 0, 0, 0.05) !important; }

.app-sidebar.bg-color-light .sidebar-footer {
  background-color: #fafafa; }

.app-sidebar .sidebar-header.bg-color-light a.collapsednav-toggler, .app-sidebar .sidebar-header.bg-color-warning a.collapsednav-toggler {
  color: rgba(0, 0, 0, 0.5); }

html,
body,
.app-header {
  background-color: #e5e5e5; }

.app-page-container .app-content-wrapper {
  background-color: #f5f5f5; }

@media only screen and (max-width: 991px) {
  .app-page-container {
    background-color: #f5f5f5; } }

.theme-gray,
.theme-dark {
  color: rgba(255, 255, 255, 0.7); }
  .theme-gray a:hover, .theme-gray a:focus,
  .theme-dark a:hover,
  .theme-dark a:focus {
    color: #2196F3; }
  .theme-gray .app-sidebar .sidebar-header,
  .theme-dark .app-sidebar .sidebar-header {
    color: #fff; }
    .theme-gray .app-sidebar .sidebar-header a,
    .theme-dark .app-sidebar .sidebar-header a {
      color: #fff; }
    .theme-gray .app-sidebar .sidebar-header a.collapsednav-toggler,
    .theme-dark .app-sidebar .sidebar-header a.collapsednav-toggler {
      color: #fff;
      opacity: .5; }
  .theme-gray .app-overlay,
  .theme-dark .app-overlay {
    background: rgba(0, 0, 0, 0.7);
    color: rgba(255, 255, 255, 0.7); }
    .theme-gray .app-overlay input,
    .theme-dark .app-overlay input {
      color: rgba(255, 255, 255, 0.7); }
    .theme-gray .app-overlay a.overlay-close,
    .theme-dark .app-overlay a.overlay-close {
      color: #868e96; }
  .theme-gray .app-sidebar .sidebar-header,
  .theme-dark .app-sidebar .sidebar-header {
    color: #555; }
    .theme-gray .app-sidebar .sidebar-header a,
    .theme-dark .app-sidebar .sidebar-header a {
      color: #555 !important; }
  .theme-gray .app-footer .brand,
  .theme-dark .app-footer .brand {
    color: rgba(255, 255, 255, 0.7); }

.theme-gray .app-sidebar {
  background-color: #3c3c3c; }
  .theme-gray .app-sidebar .sidebar-header {
    background-color: #3c3c3c; }
  .theme-gray .app-sidebar .sidebar-footer {
    background-color: #3c3c3c; }
  .theme-gray .app-sidebar .nav {
    color: #555 !important; }
    .theme-gray .app-sidebar .nav a {
      color: #555 !important; }

.theme-gray .app-page-container .app-content-wrapper {
  background-color: #444; }

@media only screen and (max-width: 991px) {
  .theme-gray .app-page-container {
    background-color: #444; } }

.theme-gray .app-header-inner.bg-color-light, .theme-gray .app-header-inner.bg-color-dark, .theme-gray .app-header-inner.bg-color-primary, .theme-gray .app-header-inner.bg-color-success, .theme-gray .app-header-inner.bg-color-info, .theme-gray .app-header-inner.bg-color-warning, .theme-gray .app-header-inner.bg-color-danger {
  background-color: #4c4c4c;
  color: rgba(255, 255, 255, 0.7); }
  .theme-gray .app-header-inner.bg-color-light a, .theme-gray .app-header-inner.bg-color-dark a, .theme-gray .app-header-inner.bg-color-primary a, .theme-gray .app-header-inner.bg-color-success a, .theme-gray .app-header-inner.bg-color-info a, .theme-gray .app-header-inner.bg-color-warning a, .theme-gray .app-header-inner.bg-color-danger a {
    color: rgba(255, 255, 255, 0.7); }

.theme-dark .app-sidebar {
  background-color: #333C44; }
  .theme-dark .app-sidebar .sidebar-header {
    background-color: #333C44; }
  .theme-dark .app-sidebar .sidebar-footer {
    background-color: #333C44; }
  .theme-dark .app-sidebar .nav {
    color: #555 !important; }
    .theme-dark .app-sidebar .nav a {
      color: #555 !important; }

.theme-dark .app-page-container .app-content-wrapper {
  background-color: #38424b; }

@media only screen and (max-width: 991px) {
  .theme-dark .app-page-container {
    background-color: #38424b; } }

.theme-dark .app-header-inner.bg-color-light, .theme-dark .app-header-inner.bg-color-dark, .theme-dark .app-header-inner.bg-color-primary, .theme-dark .app-header-inner.bg-color-success, .theme-dark .app-header-inner.bg-color-info, .theme-dark .app-header-inner.bg-color-warning, .theme-dark .app-header-inner.bg-color-danger {
  background-color: #404b55;
  color: rgba(255, 255, 255, 0.7); }
  .theme-dark .app-header-inner.bg-color-light a, .theme-dark .app-header-inner.bg-color-dark a, .theme-dark .app-header-inner.bg-color-primary a, .theme-dark .app-header-inner.bg-color-success a, .theme-dark .app-header-inner.bg-color-info a, .theme-dark .app-header-inner.bg-color-warning a, .theme-dark .app-header-inner.bg-color-danger a {
    color: rgba(255, 255, 255, 0.7); }

.theme-dark {
  background-color: #38424b; }

.theme-gray {
  background-color: #444; }
